<div class="sidesheet-tooltip-container" *ngIf="confirmAction">

    <span [ngStyle]="confirmAction?.contentStyle">
        <div class="sidesheet-tooltip-header" [ngStyle]="confirmAction?.headerStyle">
           <div class="sidesheet-tooltip-icon">
            <img src="/assets/icons/notify.svg" alt="">
          </div>
          <div class="sidesheet-tooltip-close" (click)="onClose()">
            <img src="/assets/icons/close.svg" alt="">
          </div>
      </div>
                <div class="sidesheet-tooltip-header-text"> {{ confirmAction?.textHeader | translate }}</div>
                <div class="sidesheet-tooltip-text">
                  {{ confirmAction?.textContent | translate }}
                </div>

          <div class="sidesheet-tooltip-footer">
        <button class="c4p-button-cancel sidesheet-btn-cancel" *ngIf="confirmAction?.textCancel" type="submit"
                (click)="onExecuteButtonAction(false)">
          {{'client.titles.Cancel' | translate}}
        </button>
        <button class="c4p-button sidesheet-mark-button" type="submit" *ngIf="confirmAction?.textOk"
                (click)="onExecuteButtonAction(true)">
          <img src="/assets/icons/check-white.svg" alt=""/>
          {{ confirmAction?.textOk | translate }}
        </button>
    </div>
</span>
</div>
