import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfirmAction, SideSheetConfig, SideSheetContent, SideSheetTooltipButtonEvent } from '../../../models/side-sheet';
import { SideSheetService } from '../../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidesheet-tooltip',
  templateUrl: './sidesheet-tooltip.component.html',
  styleUrls: ['./sidesheet-tooltip.component.scss']
})
export class SidesheetTooltipComponent implements OnInit, OnDestroy {
  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;

  @Output() tooltipButtonClicked = new EventEmitter<SideSheetTooltipButtonEvent>();

  confirmAction: ConfirmAction;

  private subscription: Subscription = new Subscription();

  constructor(private sideSheetService: SideSheetService) {}

  ngOnInit(): void {
    this.subscription.add(this.sideSheetService.sideSheetHost$.subscribe(data => {
      this.closeTooltip();
    }));

    this.subscription.add(this.sideSheetService.tooltipSubject$.subscribe(confirmAction => {
      this.confirmAction = confirmAction;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeTooltip() {
    this.confirmAction = null;
  }

  onExecuteButtonAction(confirmed: boolean) {
    this.tooltipButtonClicked.emit({ button: this.confirmAction?.button, confirmed, config: this.sideSheetConfig, content: this.sideSheetContent });
    this.closeTooltip();
  }

  onClose() {

  }
}
