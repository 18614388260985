import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  MatTableDataSource,
} from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { TableColumn } from './table-columns.interface';
import { UserInfoService } from '../../services';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tuula-table',
  templateUrl: './tuula-table.component.html',
  styleUrl: './tuula-table.component.scss',
})
export class TuulaTableComponent implements OnInit {

  get pagination() {
    return this._pagination;
  }

  get rows() {
    return this._rows;
  }

  dataSource = new MatTableDataSource();
  @Input() set rows(value) {
    this._rows = value;
    this.dataSource.data = value;
  };
  @Input() columns: TableColumn[];
  @Input() title;
  @Input() tableControls;
  @Input() set pagination(value) {
    if (this.userSettings?.pageSize && !this.pagination) {
      value.pageSize = this.userSettings.pageSize
    }
    this._pagination = value;
  };
  @Input() tableName;
  @Input() pageSizeOptions = [5, 10, 20, 50];

  @Output() applyFilter = new EventEmitter<void>();
  @Output() changePage = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<void>();

  tableDisplayedColumns = [];
  tableRowSize = 'default';
  userSettings;
  _pagination;
  _rows;

  private readonly pageParamsChangedSubject = new Subject<any>();
  private readonly destroyed = new Subject<boolean>();

  constructor(private userInfoService: UserInfoService) {
  }

  ngOnInit() {
    this.columns = this.columns?.filter((column) => {
      return !column.visibilityFunc || column.visibilityFunc();
    });
    this.dataSource.data = this.rows;
    this.getUserSettings();
    this.updateVisibleColumns();
    this.pageParamsChangedSubject.pipe(takeUntil(this.destroyed), debounceTime(500))
      .subscribe((filter) => {
        this.pagination.page = filter.pageIndex + 1;
        this.pagination.pageSize = filter.pageSize;
        this.pagination.totalDocs = filter.length;
        this.updateUserSettings();
        this.changePage.emit(filter);
      });
    this.handlePage(this.pagination);
  }

  getNestedValue(row, column) {
    const val = column.prop.split('.').reduce((o, i) => (o && o[i] !== undefined) ? o[i] : undefined, row);
    if (column.pipe && val) {
      const pipe = column.pipe;
      return pipe.transform(val);
    }
    return val;
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousProp = this.tableDisplayedColumns[event.previousIndex];
    const currentProp = this.tableDisplayedColumns[event.currentIndex];
    const findPreviousColumnIndex = this.columns.findIndex((column) => column.prop === previousProp);
    const findCurrentColumnIndex = this.columns.findIndex((column) => column.prop === currentProp);
    moveItemInArray(this.columns, findPreviousColumnIndex, findCurrentColumnIndex);
    moveItemInArray(this.tableDisplayedColumns, event.previousIndex, event.currentIndex);
    this.updateUserSettings();
  }

  handlePage(event) {
    this.pageParamsChangedSubject.next(event);
  }

  handleSort(event) {
    this.sortChange.emit(event);
  }

  onToggleColumn(column) {
    column.hidden = !column.hidden;
    this.updateVisibleColumns();
  }

  onPinColumn(column) {
    column.frozenLeft = !column.frozenLeft;
    this.updateVisibleColumns();
  }

  updateVisibleColumns() {
    this.updateUserSettings();
    this.tableDisplayedColumns = this.columns.filter((column) => !column.hidden).map((column) => column.prop);
  }

  onResizeColumn(event, column) {
  }

  getUserSettings() {
    if (this.tableName) {
      this.userSettings = this.userInfoService.getTableViewForPage(this.tableName);

      if (this.userSettings?.pageSize) {
        this.pagination.pageSize = this.userSettings.pageSize
      }

      const updatedColumns = this.columns.map((column) => {
        const columnSettings = this.userSettings.columns.find((col) => col.prop === column.prop);
        if (columnSettings) {
          column.hidden = columnSettings.hidden;
          column.frozenLeft = columnSettings.frozenLeft;
        }
        return column;
      });

      this.columns = updatedColumns.map((col) => {
        const userCol = this.userSettings.columns.find((userCol) => userCol.prop === col.prop);
        if (userCol) {
          return {
            ...col,
            hidden: userCol.hidden,
            frozenLeft: userCol.frozenLeft,
          };
        }
        return col;
      });
    }
  }

  updateUserSettings() {
    if (this.tableName && this.userSettings) {
      const columnCopy = this.columns.map((column) => {
        return {
          prop: column.prop,
          hidden: column.hidden,
          frozenLeft: column.frozenLeft,
        };
      });
      this.userInfoService.setTableViewForPage(this.tableName, { columns: columnCopy, pageSize: this.pagination.pageSize });
    }
  }
}
