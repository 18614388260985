import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { CategoryService } from '../../service/category/category.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CategoryModel } from '../../models/category.model';
import { slideFromTop } from '../../../general-components/animations';
import {
  ITableOption,
  ITableView,
  TableViewComponent,
} from '../../../general-components/table-view/table-view.component';
import { PermissionService } from '../../../services/permissions/permission.service';
import { CategoryDetailComponent } from '../../components/category-detail/category-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { PageMenuService } from '../../../services';

@Component({
  selector: 'app-category-overview',
  templateUrl: './category-overview.component.html',
  styleUrls: ['./category-overview.component.scss'],
  animations: [slideFromTop],
})
export class CategoryOverviewComponent implements OnInit {
  public tableOptions: ITableOption = null!;

  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 20,
    sortColumn: 'name',
    sortDirection: 'asc',
    search: '',
  };

  menuActions = [];

  @ViewChild(TableViewComponent) tableViewComponent: TableViewComponent;
  private _toggleFilters = false;
  public get toggleFilters() {
    return this._toggleFilters;
  }
  public set toggleFilters(toggled: boolean) {
    // reset tableview
    this.tableView.pageIndex = 0;
    this.tableView.search = null;
    // reset params
    this._filterParams = {};
    // reset table component
    this.tableViewComponent.pageIndex = 0;

    this._toggleFilters = toggled;
    this.refresh();
  }

  private _filterParams = {};
  public get filterParams() {
    return this._filterParams;
  }
  public set filterParams(filterParams: any | null) {
    this._filterParams = filterParams;
    this.tableViewComponent.pageIndex = 0;
    this.tableView.pageIndex = 0;
  }

  constructor(
    private categoryService: CategoryService,
    private permissionService: PermissionService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private pageMenuService: PageMenuService,
  ) {}

  ngOnInit(): void {
    this.tableOptions = this.buildTable();
    this.setMenuItems();
    // we don't have a resovler here
    this.refresh();
  }

  public refresh(): void {
    this.categoryService
      .getCategories(this.tableView.search)
      .subscribe((result) => {
        this.categoryService.categories$.next(result);
      });
  }

  public create(): void {
    const dialogRef = this.dialog.open(CategoryDetailComponent, {});
    dialogRef.afterClosed().subscribe((category) => {
      for (var i = category?.funcs.length - 1; i >= 0; i--) {
        if (category.funcs[i]['checked'] == false) {
          category.funcs.splice(i, 1);
        }
      }
      if (category) {
        this.categoryService
          .createCategory(category)
          .subscribe((createdCategory) => {
            this.toastr.success(
              this.translate.instant('general.labels.Success'),
            );
            this.refresh();
          });
      }
    });
  }

  public delete(data: CategoryModel): void {
    this.categoryService.deleteCategory(data.id).subscribe(
      (data) => {
        this.toastr.success(this.translate.instant('general.labels.Success'));
        this.refresh();
      },
      (error) => {},
    );
  }

  public edit(category: CategoryModel): void {
    const dialogRef = this.dialog.open(CategoryDetailComponent, {
      data: { category },
    });
    dialogRef.afterClosed().subscribe((updatedCategory) => {
      for (var i = updatedCategory?.funcs.length - 1; i >= 0; i--) {
        if (updatedCategory.funcs[i]['checked'] == false) {
          updatedCategory.funcs.splice(i, 1);
        }
      }
      if (updatedCategory) {
        this.categoryService.updateCategory(updatedCategory).subscribe(() => {
          this.toastr.success(this.translate.instant('general.labels.Success'));
          this.refresh();
        });
      }
    });
  }

  private setMenuItems(): void {
    this.pageMenuService.setMenuItems(this.menuActions);
  }
  buildTable():ITableOption{
    return {
      name: 'document.labels.Categories',
      dataObservable: this.categoryService.categories$.pipe(
          map((categories) => {
            if (categories) return { data: categories, count: categories?.length };
          }),
      ),
      columns: [
        {
          id: 'name',
          name: 'document.labels.Name',
          type: 'string',
          format: '',
        },
      ],
      inlineActions: [],
      menuActions: [
        {
          icon: 'create',
          tooltip: 'general.actions.Edit',
          method: (data) => this.edit(data),
          permission: 'documentCategory:update',
        },
        {
          icon: 'delete',
          tooltip: 'general.actions.Delete',
          method: (data) => this.delete(data),
          permission: 'documentCategory:delete',
        },
      ],
      showAdd: this.permissionService.getPermission('documentCategory:create'),
      showHeader: true,
      showSearch: true,
      showPagination: false,
      internalSort: true,
      externPagination: true,
      defaultPageSize: 20,
      defaultSortColumn: 'name',
      defaultSortDirection: 'asc',
    };
  }
}
