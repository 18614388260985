<div style="display: flex">
  <!-- Visible Buttons -->
  <ng-container *ngFor="let button of visibleButtons">
    <ng-container *ngTemplateOutlet="button.template"></ng-container>
  </ng-container>

  <ng-container *ngIf="overflowButtons.length > 0">
    <div [matMenuTriggerFor]="tableRowOverflowMenu">
      <mat-icon class="c4p-action-badge">more_vert</mat-icon>
    </div>
  </ng-container>
</div>

<mat-menu #tableRowOverflowMenu="matMenu" class="c4p-menu">
  <ng-container *ngFor="let button of overflowButtons">
      <ng-container *ngTemplateOutlet="button.template"></ng-container>
  </ng-container>
</mat-menu>