<div class="notification-tooltip">
  <div class="notification-tooltip-container">
    <div class="notification-tooltip-header">
      <div class="notification-tooltip-icon">
        <img src="/assets/icons/notify.svg" alt="">
      </div>
      <div class="notification-tooltip-close" (click)="onClose()">
        <img src="/assets/icons/close.svg" alt="">
      </div>
    </div>
    <div class="notification-tooltip-content">
      <div class="notification-tooltip-title">{{'notification.titles.MarkAllMessages' | translate}}</div>
      <div class="n-title">{{'notification.messages.MarkAllInformMessages' | translate}}</div>
      <div class="">
        <section>
          <mat-radio-group
            class="n-radio-group"
            [(ngModel)]="notificationAllMark">
            <mat-radio-button class="n-radio-button"  [value]=false > {{'notification.messages.MarkOnThisPage' | translate  }}</mat-radio-button>
            <div class=""></div>
            <mat-radio-button class="n-radio-button"  [value]="true"> {{'notification.messages.MarkAll' | translate  }}</mat-radio-button>
          </mat-radio-group>

        </section>
      </div>
    </div>
    <div class="notification-tooltip-footer">
        <button class="c4p-button-cancel n-btn-cancel" type="submit" (click)="onClose()">
          {{'client.titles.Cancel' | translate}}
        </button>
        <button translate class="c4p-button n-mark-button" type="submit" (click)="onMarkAllAsRead()">
          <img src="/assets/icons/check-white.svg" alt="" />
          {{'notification.labels.MarkAllMessages' | translate}}
        </button>
    </div>
  </div>
</div>
