import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, map, distinctUntilChanged, tap } from 'rxjs/operators';
import { LOCAL_STORAGE_CLIENT_KEY } from '../client-info/client-info.service';

const ROLES_EXCLUDED = ['uma_authorization', 'offline_access', 'default-roles-'];
const LOCAL_STORAGE_CURRENT_ROLE_KEY = 'currentRole';
const LOCAL_STORAGE_PERMISSION_KEY = 'permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  url = `${this.environment.systemAdminApiUrl}/permissions`;

  public permissions$ = new BehaviorSubject<any>(null);
  public currentRole$ = new BehaviorSubject<string>(this.currentRole);
  public userRoles$ = new BehaviorSubject<string[]>(null);
  public userAccountAccess$ = new BehaviorSubject<string[]>(null);

  get currentRole() {
    return localStorage.getItem(LOCAL_STORAGE_CURRENT_ROLE_KEY);
  }

  set currentRole(role: string) {
    localStorage.setItem(LOCAL_STORAGE_CURRENT_ROLE_KEY, role);
    this.currentRole$.next(role);
  }

  public set permissions(permissions: string[]) {
    localStorage.setItem(
      LOCAL_STORAGE_PERMISSION_KEY,
      JSON.stringify({ permissions: permissions }),
    );
    this.permissions$.next(permissions);
  }

  public get permissions(): string[] {
    if (JSON.parse(localStorage.getItem(LOCAL_STORAGE_PERMISSION_KEY))) {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PERMISSION_KEY))
        .permissions;
    } else return null;
  }

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public initUserPermissions(token: any): void {
    this.userRoles$.next(token.realm_access.roles);
    this.userAccountAccess$.next(token.resource_access.account);
  }

  public initRolePermissions(tokenData: string): Observable<string[]> {
    return this.getRolePermissions(tokenData).pipe(
      tap((permissions: any) => {
        this.permissions = permissions;
      }),
    );
  }

  public setClientRole(roles: string[]) {
    let clientRole = roles.filter((role) => {
      return !this.isDefaultRole(role)})[0];
    localStorage.setItem(LOCAL_STORAGE_CURRENT_ROLE_KEY, clientRole);
    this.currentRole$.next(clientRole);
  }

  public isDefaultRole(role: string): boolean {
    return ROLES_EXCLUDED.some(excludedRole => role.startsWith(excludedRole));
  }

  public getPermission(permission: string, operation?: 'and' | 'or'): boolean {
    const permissionArray: string[] = permission
      .split('|')
      .map((permissionString) => permissionString.trim());
    let hasPermission: boolean;

    if (this.permissions) {
      // handle carefile escalate
      // TODO: Remove
      // if (permission !== 'carefile:escalate' && permission.includes('carefile')) {
      //   if (this.permissions.includes('carefile:escalate')) return true;
      // }

      if (permissionArray.length > 0 && operation) {
        hasPermission = this.calculateArrayOfPermissions(
          permissionArray,
          operation,
        );
      } else {
        hasPermission = this.permissions.includes(permission);
      }
    }

    // if (!hasPermission) console.log(`%c ಠ_ಠ missing permission: ${permission}`, 'background: #222; color: #bada55');
    return hasPermission;
  }

  // turd of a fix for carefile permissions handling
  public getEntityPermission({
    permission,
    operation,
    entity,
  }: {
    permission: string;
    operation?: 'and' | 'or';
    entity: any;
  }): {
    escalated: boolean;
    hasPermission: boolean;
  } {
    // if you are trying to call this function directly, your providers aren't configured
    throw new Error(
      'This method should only be called from a derived PermissionService',
    );
  }

  // turdfix
  // used for page menu
  public getOverloadedPermission(
    permission: string,
    operation?: 'and' | 'or',
  ): boolean {
    // if you are trying to call this function directly, your providers aren't configured
    throw new Error(
      'This method should only be called from a derived PermissionService',
    );
  }

  public getPermissionObservable(permission: string): Observable<boolean> {
    return this.permissions$.pipe(
      filter((permissions) => permissions !== null),
      distinctUntilChanged(),
      map((permissions) => permissions.includes(permission)),
    );
  }

  private calculateArrayOfPermissions(
    permissionArray: string[],
    operation: 'and' | 'or',
  ): boolean {
    return permissionArray
      .map((permissionString) => this.permissions.includes(permissionString))
      .reduce((a, b) => {
        if (operation === 'and') {
          return a && b;
        } else if (operation === 'or') {
          return a || b;
        }
      });
  }

  public logout(): void {
    localStorage.removeItem(LOCAL_STORAGE_PERMISSION_KEY);
    localStorage.removeItem(LOCAL_STORAGE_CURRENT_ROLE_KEY);
    sessionStorage.removeItem(LOCAL_STORAGE_CLIENT_KEY);
  }

  // HTTP
  private getRolePermissions(tokenData: string): Observable<string[]> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/permissions',
      authorization: `Bearer ${tokenData}`,
    });
    return this.http.post<string[]>(
      this.url,
      { token: tokenData },
      { headers: httpHeaders },
    );
  }

  public async  getRolePermissionsAsync(tokenData: string): Promise<string[]> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/permissions',
      authorization: `Bearer ${tokenData}`,
    });
    return await new Promise((resolve,reject) => {
      this.http.post<string[]>(
        this.url,
        { token: tokenData },
        { headers: httpHeaders },
      ).subscribe(res => resolve(res));
    })
  }

  // public init() {}

  // public create() {}

  // public destroy() {}

  // public update() {}

  // public getPermissionsByRoleId(roleId: string): Observable<Permission[]> {
  //   return this.http.get<Permission[]>(this.url + `/function/${roleId}`);
  // }

  // public getAll() {
  //   return this.http.get<Permission>(this.url);
  // }

  // public getResourcePermissions(resourceIds): Observable<Permission[]> {
  //   return this.http.post<Permission[]>(this.url + `/resources`, resourceIds);
  // }
}
