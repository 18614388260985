import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EMAIL } from '../../utilities';

@Component({
  selector: 'app-service-desk-form',
  templateUrl: './service-desk.component.html',
  styleUrls: ['./service-desk.component.scss'],
})
export class ServiceDeskComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ServiceDeskComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  emailAddress: string =
    this.data.employeePortal && !this.data.clientPortal
      ? EMAIL.EMPLOYEE
      : EMAIL.GENERAL;

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(false);
  }
}
