<div class="tuula-table-container">
  <div class="tuula-table-top-bar">
    <div class="tuula-table-title">{{ title | translate }}</div>
    <div class="tuula-table-controls">
      <ng-container *ngTemplateOutlet="tableControls">
      </ng-container>
      <button class="tuula-table-control-button">
        <mat-icon>filter_list</mat-icon>
      </button>
      <button [matMenuTriggerFor]="tableControlsMenu" class="tuula-table-control-button">
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </div>
  <div cdkDropList
       cdkDropListOrientation="horizontal"
       (cdkDropListDropped)="drop($event)">
    <mat-table [dataSource]="dataSource" class="tuula-table"
               matSort (matSortChange)="handleSort($event)">
      @for (column of columns; track column) {
        <ng-container [matColumnDef]="column.prop" [sticky]="column.frozenLeft" [stickyEnd]="column.frozenRight">
          <mat-header-cell cdkDrag cdkDragLockAxis="x" [cdkDragDisabled]="column.draggable === false" *matHeaderCellDef
                           mat-sort-header [disabled]="column.sortable === false"
                           [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : 180, 'justify-content': column.justify ? column.justify :'start' }"
          >
            @if (column.headerTemplate) {
              <ng-container
                *ngTemplateOutlet="column.headerTemplate; context: { column: column }">
              </ng-container>
            } @else {
              {{ column.name | translate }}
            }
            <span class="resize-handle" (mousedown)="onResizeColumn($event, column)"></span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index"
                    [ngClass]="[tableRowSize === 'default'? 'tuula-table-row-default' : 'tuula-table-row-compact']"
                    [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : 180, 'justify-content': column.justify ? column.justify :'left' }"
          >
            @if (column.cellTemplate) {
              <ng-container
                *ngTemplateOutlet="column.cellTemplate; context: { row: row, rowIndex: i, rowValue: getNestedValue(row, column) }">
              </ng-container>
            } @else {
              <span
                [matTooltip]="getNestedValue(row, column)">
                {{ getNestedValue(row, column) }}
            </span>
            }
          </mat-cell>
        </ng-container>
      }
      <mat-header-row *matHeaderRowDef="tableDisplayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></mat-row>

    </mat-table>
  </div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions"
                 [showFirstLastButtons]="false"
                 [length]="pagination?.totalDocs"
                 (page)="handlePage($event)"
                 [pageSize]="pagination?.pageSize"
                 [pageIndex]="pagination?.page - 1"
  >
  </mat-paginator>
</div>

<mat-menu #tableControlsMenu class="c4p-menu tuula-table-settings-menu">
    <div (click)="$event.stopPropagation()" class="table-row-size-toggle">
      <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [(ngModel)]="tableRowSize" class="toggle-group">
        <mat-button-toggle value="default" class="toggle-button">
          <mat-icon>view_module</mat-icon>
          Default
        </mat-button-toggle>
        <mat-button-toggle value="compact" class="toggle-button">
          <mat-icon>view_comfy</mat-icon>
          Compact
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div style="max-height: 50vh; display: flex; flex-direction: column; overflow-y: auto">
      @for (column of columns; track column) {
        @if (!column.permanent) {
          <div mat-menu-item class="c4p-menu-item" (click)="$event.stopPropagation()"
          >
            <mat-icon (click)="onToggleColumn(column)"
                      class="c4p-action-menu-icon">{{ column.hidden ? 'remove_red_eye' : 'visibility_off' }}
            </mat-icon>
            <mat-icon (click)="onPinColumn(column)" [class.disabled]="column.hidden"
                      class="c4p-action-menu-icon">{{ column.frozenLeft ? 'pin_drop' : 'place' }}
            </mat-icon>
            <span class="c4p-menu-title">{{ column.name | translate }}</span>
          </div>
        }
      }
    </div>
</mat-menu>
