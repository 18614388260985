<div class="c4p-form-input" [ngClass]="{ 'readonly-wrapper': readonly }">
  <mat-form-field>
    <mat-label>{{placeholder | translate}}</mat-label>

    <input
      [matDatepicker]="datePicker"
      [matDatepickerFilter]="dateFilter"
      [errorStateMatcher]="errorStateMatcher"
      matInput
      [min]="getMinDate"
      [max]="getMaxDate"
      [(ngModel)]="value"
      [disabled]="isDisabled"
      [required]="required"
      (blur)="onTouchedCallback()"
      maxlength="10"
      (keydown)="onKeydown($event)"
      (dblclick)="setNow()"
      #datePickerInput
      #valueInput="ngModel"
      [readonly]="readOnly"
      (dateChange)="onChangeDate()"
      (change)="valueChangeHandler($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker
      #datePicker
      [startView]="startView"
      (monthSelected)="chosenMonthHandler($event, datePicker)"
      (opened)="onOpenedDateStream()"
      (closed)="onClosedDateStream()"
    ></mat-datepicker>

    <!--mat-error *ngFor="let error of valueInput.errors | keyvalue">
      <span
        *ngIf="
          errorLabels && errorLabels[error.key];
          else defaultValueControlErrors
        "
      >
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container
        #defaultValueControlErrors
        *ngIf="DEFAULT_DATEPICKER_ERRORS.includes(error.key)"
      >
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        <span *ngIf="error.key === 'matDatepickerMin'">{{
          transformaMinError(error.value?.min) | appDate
        }}</span>
        <span *ngIf="error.key === 'matDatepickerMax'">{{
          transformMaxError(error.value?.max) | appDate
        }}</span>
      </ng-container>
    </mat-error-->
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      <span
        *ngIf="
          errorLabels && errorLabels[error.key];
          else defaultNgControlErrors
        "
      >
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container
        #defaultNgControlErrors
        *ngIf="DEFAULT_ERRORS.has(error.key)"
      >
        <span
          *ngIf="
            error.key !== 'matDatepickerMin' && error.key !== 'matDatepickerMax'
          "
          >{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span
        >
      </ng-container>
    </mat-error>
  </mat-form-field>
</div>
