<div class="c4p-question-set-input-small" style="word-wrap: break-word">
  <span *ngIf="required" class="c4p-question-label">{{
    '* ' + orderNum + '. ' + placeholder
  }}</span>
  <span *ngIf="!required" class="c4p-question-label">{{
    orderNum + '. ' + placeholder
  }}</span>
  <span
    *ngIf="hint && !isClientPortal"
    tippy
    [tippyOptions]="{
      content: hint,
      arrow: true,
      allowHTML: true,
      placement: 'right-end',
      theme: 'light',
      maxWidth: '350px',
      interactive: true
    }"
  >
    <mat-icon class="c4p-hint-icons">info</mat-icon>
  </span>
</div>
<div class="c4p-question-set-input-scale">
  <!-- TODO: The 'tickInterval' property no longer exists -->
  <mat-slider
    [formControl]="valueControl"
    [required]="required"
    [displayWith]="formatLabel"
    [min]="minLimit"
    [max]="maxLimit"
    ngDefaultControl
    matInput
    thumbLabel
    style="margin-top: 15px; width: 300px"
    #matSlider
    (keydown.Tab)="onTab(matSlider)"
   #ngSlider><input value={{valueControl.value}} matSliderThumb (change)="onScaleChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" />
  </mat-slider>

  <ng-container *ngIf="ngControl.touched && ngControl.value == '' && required">
    <div
      class="mat-error-custom"
      *ngFor="let error of ngControl.errors | keyvalue"
    >
      <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
      </ng-container>
    </div>
  </ng-container>
</div>
