import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicFormArray } from '../models/dynamic-form-array.model';
import { DynamicFormGroup } from '../models/dynamic-form-group.model';
import { DynamicFormInput } from '../models/dynamic-form-input.model';
import { DynamicTextInput } from '../models/dynamic-text-input.model';
import { DynamicCheckboxInput } from '../models/dynamic-checkbox-input.model';
import { DynamicReturnCode } from '../models/dynamic-return-code.model';
import { DynamicFormInputOrGroup } from '../models/dynamic-form-types';
@Injectable({ providedIn: 'root' })
export class DynamicFormService {
  constructor(private formBuilder: FormBuilder) {}

  // generate our own models used for rendering and extracting all of the informatio needed to generate angular form controls
  generateDynamicFormGroup(
    jsonObject: any,
    formGroupName: string,
    fieldType: string,
    icom?: boolean,
  ): DynamicFormGroup {
    const type = fieldType ? fieldType : 'text';
    const dynamicFormGroup = new DynamicFormGroup(formGroupName);

    for (const [key, value] of Object.entries(jsonObject)) {
      if (Array.isArray(value) && formGroupName) {
        const dynamicFormArray = new DynamicFormArray(key);
        for (let i = 0; i < value.length; i++) {
          const arrayValue = value[i];
          let arrayControl: DynamicFormGroup | DynamicFormInput<any>;
          if (typeof arrayValue === 'object' && !!value) {
            arrayControl = this.generateDynamicFormGroup(
              arrayValue,
              i.toString(),
              type,
              icom,
            );
          } else {
            arrayControl = this.createDynamicInput(
              arrayValue,
              i.toString(),
              type,
            );
          }
          dynamicFormArray.controls.push(arrayControl);
        }
        dynamicFormGroup.setControl(key, dynamicFormArray);
      } else if (
        typeof value === 'object' &&
        !!value &&
        formGroupName !== 'RetourCodes'
      ) {
        dynamicFormGroup.setControl(
          key,
          this.generateDynamicFormGroup(value, key, type, icom),
        );
      } else if (jsonObject.hasOwnProperty(key)) {
        dynamicFormGroup.setControl(
          key,
          this.createDynamicInput(value, key, type),
        );
        if (!icom) {
          dynamicFormGroup.setControl(
            'allowAllPermissions',
            this.createDynamicInput(false, 'allowAllPermissions', 'checkbox'),
          );
        }
      }
      if (icom) dynamicFormGroup.removeControl('allowAllPermissions');
    }

    return dynamicFormGroup;
  }

  // use our own model to generate angular FormGroup
  generateFormGroup(
    dynamicFormGroup: DynamicFormGroup,
    icom?: boolean,
  ): FormGroup {
    const formGroupData = {};

    for (let [key, dynamicFormElement] of dynamicFormGroup.controls) {
      if (dynamicFormElement instanceof DynamicFormInput) {
        formGroupData[key] = this.formBuilder.control(dynamicFormElement.value);
      } else if (dynamicFormElement instanceof DynamicFormGroup) {
        formGroupData[key] = this.generateFormGroup(dynamicFormElement, icom);
      } else if (dynamicFormElement instanceof DynamicFormArray) {
        const arrayElements = [];
        for (let i = 0; i < dynamicFormElement.controls.length; i++) {
          const arrayControl: DynamicFormInputOrGroup =
            dynamicFormElement.controls[i];
          if (arrayControl instanceof DynamicFormGroup) {
            arrayElements.push(this.generateFormGroup(arrayControl, icom));
          } else if (arrayControl instanceof DynamicFormInput) {
            arrayElements.push(this.formBuilder.control(arrayControl.value));
          }
        }
        formGroupData[key] = this.formBuilder.array(arrayElements);
      }
    }
    if (!icom) {
      formGroupData['allowAllPermissions'] = false;
    }
    return this.formBuilder.group(formGroupData);
  }

  parseJsonString(jsonString: string): any {
    let jsonObject: any;
    try {
      jsonString = jsonString
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, '\\"')
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
      jsonString = jsonString.replace(/[\u0000-\u0019]+/g, '');
      jsonObject = JSON.parse(jsonString);
    } catch (error) {
      throw error;
    }
    return jsonObject;
  }

  createDynamicInput(value: any, key: string, type: string): any {
    let dynamicInput;
    if (key === 'RetourCode') type = 'returnCode';
    switch (type) {
      case 'text':
        dynamicInput = new DynamicTextInput({
          value: value ? value.toString() : null,
          key: key,
          placeholder: key,
          type: type,
        });
        break;
      case 'checkbox':
        dynamicInput = new DynamicCheckboxInput({
          value: Boolean(value),
          key: key,
          placeholder: key,
          type: type,
        });
        dynamicInput;
        break;
      case 'returnCode':
        dynamicInput = new DynamicReturnCode({
          value: value ? value.toString() : null,
          key: key,
          placeholder: key,
          type: type,
        });
        break;
      default:
        break;
    }
    return dynamicInput;
  }
}
