import { Subject } from 'rxjs';
import {Injectable, Type} from '@angular/core';
import {
  Button,
  ConfirmAction,
  SideSheetConfig,
  SideSheetContent,
  SidesheetRef,
} from '../../models';
import {
  createButton,
  createConfirmAction,
  createDefaultSideSheetConfig,
  createDefaultSideSheetContent,
  createSideSheetBackButton,
  createSideSheetCancelButton,
  createSideSheetCloseButton,
  createSideSheetCreateButton,
  createSideSheetDeleteButton,
  createSideSheetEditButton,
  createSideSheetOkButton,
  createSideSheetSaveButton,
} from './initial-side-sheet-config';

@Injectable({
  providedIn: 'root',
})
export class SideSheetService {
  private sideSheetHost = new Subject<any>();
  sideSheetHost$ = this.sideSheetHost.asObservable();

  private closeSidesheetSubject = new Subject<any>();
  closeSidesheetSubject$ = this.closeSidesheetSubject.asObservable();

  private closeComponentSubject = new Subject<any>();
  closeComponentSubject$ = this.closeComponentSubject.asObservable();

  private tooltipSubject = new Subject<ConfirmAction>();
  tooltipSubject$ = this.tooltipSubject.asObservable();

  constructor() {}

  public loadComponent(
    component: Type<unknown>,
    content: Partial<SideSheetContent>,
    config: Partial<SideSheetConfig>,
    clearContent: boolean = false,
  ): SidesheetRef {
    const sidesheetRef: SidesheetRef = new SidesheetRef();

    this.sideSheetHost.next({
      component: component,
      content: content,
      config: config,
      sidesheetRef,
      clearContent: clearContent,
    });
    return sidesheetRef;
  }

  public closeSideSheet(): void {
    this.closeSidesheetSubject.next(true);
  }

  public closeComponent(data: any = null): void {
    this.closeComponentSubject.next(data);
  }

  public openTooltip(confirmAction: ConfirmAction): void {
    this.tooltipSubject.next(confirmAction);
  }

  public closeTooltip() {
    this.tooltipSubject.next(null);
  }

  public createConfirmAction(
    button: Button,
    title?: string,
    content?: string,
  ): ConfirmAction {
    return createConfirmAction(button, title, content);
  }

  public createButton(
    name: string,
    actionType: string,
    style?: any,
    classes?: string[],
  ): Button {
    return createButton(name, actionType, style, classes);
  }
  createCloseButton(): Button {
    return createSideSheetCloseButton();
  }
  createBackButton(): Button {
    return createSideSheetBackButton();
  }
  createCancelButton(): Button {
    return createSideSheetCancelButton();
  }
  createEditButton(): Button {
    return createSideSheetEditButton();
  }
  createCreateButton(): Button {
    return createSideSheetCreateButton();
  }
  createSaveButton(): Button {
    return createSideSheetSaveButton();
  }
  createOkButton(): Button {
    return createSideSheetOkButton();
  }
  createDeleteButton(): Button {
    return createSideSheetDeleteButton();
  }

  createDefaultSideSheetConfig() {
    return createDefaultSideSheetConfig();
  }
  createDefaultSideSheetContent() {
    return createDefaultSideSheetContent();
  }
}
