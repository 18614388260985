import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';
import { TenantConfigurationService } from './tenant-configuration.service';

export function initializeKeycloak(
  keycloak: KeycloakService,
  configService: TenantConfigurationService,
) {
  return async () => {
    const configuration = await configService.getKeycloakConfiguration();

    let keycloakConfig: KeycloakConfig = {
      url: configuration.keycloakHost,
      realm: configuration.keycloakRealmName,
      clientId: 'c4p-client-portal',
    };

    return keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      bearerExcludedUrls: [],
    });
  };
}
