import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TuulaTableActionButtonComponent } from '../tuula-table-action-button/tuula-table-action-button.component';

@Component({
  selector: 'app-tuula-table-action-column',
  templateUrl: './tuula-table-action-column.component.html',
  styleUrl: './tuula-table-action-column.component.css'
})
export class TuulaTableActionColumnComponent implements AfterContentInit{

  @Input() numberOfVisibleButtons: number = 0;
  @ContentChildren(TuulaTableActionButtonComponent) actionButtons!: QueryList<TuulaTableActionButtonComponent>;

  visibleButtons: TuulaTableActionButtonComponent[] = [];
  overflowButtons: TuulaTableActionButtonComponent[] = [];

  constructor() { }

  ngAfterContentInit() {
    this.manageButtonsVisibility();
  }

  private manageButtonsVisibility() {
    const buttonsArray = this.actionButtons.toArray();
    this.visibleButtons = buttonsArray.slice(0, this.numberOfVisibleButtons);
    this.overflowButtons = buttonsArray.slice(this.numberOfVisibleButtons);
    this.overflowButtons?.forEach(button => button.overflow = true);
  }

}
