export class GeneralUtil {
  static formatNumber(value: string): string {
    const [integerPart, decimalPart] = value.split(',');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedValue = decimalPart
      ? `${formattedInteger},${decimalPart}`
      : formattedInteger;

    return formattedValue;
  }

  static traverse(obj: any) {
    try {
      if (obj === null || obj === undefined) {
        return obj;
      }
      if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
          obj.forEach((item: any, index: number) => {
            obj[index] = this.traverse(item);
          });
        } else {
          Object.keys(obj).forEach((key) => {
            obj[key] = this.traverse(obj[key]);
          });
        }
      } else if (typeof obj === 'string') {
        if (/^\d{4,},\d{2}$/.test(obj)) {
          return this.formatNumber(obj);
        }
      }
    } catch (error) {
      return obj;
    }

    return obj;
  }
}
